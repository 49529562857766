import React, { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../app/constants';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://helloworld-j376pdtdya-uc.a.run.app/forget/forgetPassword', { email });
      alert('Password reset link sent');
    } catch (error) {
      alert('Error sending password reset link');
    }
  };

  return (
   <div className=' px-3 py-7  '>
    <form onSubmit={handleSubmit} className=' bg-white shadow-lg w-[94vw] h-[70vh]  rounded-2xl' >
    <p className=' text-center text-3xl'>Shamaim</p>

     <div className=' flex flex-col justify-center items-start px-6 h-[30vh]   gap-3 mt-10'>
      <p className=' text-center'> Email</p>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className=' w-[75vw] rounded-xl mb-6 '
      />

      <button type="submit " className=' bg-orange-300 py-2 px-7 rounded-lg text-gray-500 '>Send Reset Link</button>
      </div>
    </form>
</div>
  );
};

export default ForgotPassword;
