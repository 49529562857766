import React, { useState } from 'react';
import { baseUrl } from '../../../app/constants';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
   const navigate=useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://helloworld-j376pdtdya-uc.a.run.app/forget/reset-password/${token}`, { password });
      alert('Password Upadetd successful');
      navigate('/login');
    } catch (error) {
      alert('Error resetting password');
    }
  };

  return (
    <div className=' px-3 py-7  '>

    <form onSubmit={handleSubmit} className=' bg-white shadow-lg w-[94vw] h-[70vh]  rounded-2xl' >
    <p className=' text-center text-3xl'>Shamaim</p>
     <div className=' flex flex-col justify-between items-start     mt-10 h-[37vh] px-6'>

      <label>New Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className=' w-[75vw] rounded-xl  mt-1 mb-2 '

      />
            <label>Confirm password:</label>

          <input
        type="password"
        // onChange={(e) => setPassword(e.target.value)}
        className=' w-[75vw] rounded-xl mt-1 mb-2 '

      />
      <button type="submit " className=' bg-orange-300 py-2 px-7  mt-2 rounded-lg text-gray-500 '>Update Password</button>
      </div>

    </form>
    </div>
  );
};

export default ResetPassword;
